<template>
  <v-container>
    <!-- <div style="margin-top: 20px; margin-bottom: 20px; margin-left: auto; margin-right: auto; height: 200px; width: 80%; overflow-y: auto; overflow-x: auto; border-style: solid; border-width: 1px; padding-top: 10px; padding-left: 10px; padding-bottom: 10px; padding-right: 10px"> -->
    <!-- <v-card elevation="6" height="200px" style="margin-left: 20px; margin-right: 20px; margin-top: 20px; margin-bottom: 20px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; padding-right: 10px; overflow-y: auto"> -->
    
      
    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <h2 class="ml-4 mr-4 mb-0 font-weight-bold">{{cuestionario.Titulo}}</h2>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <img v-bind:src="'https://d23esi1h40dfmi.cloudfront.net/wp-content/uploads/2021/06/08083209/Logo_2_tintas-e1623141346682.png'" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <p class="ml-8 mr-8">Hola, <span style="font-weight: bold">{{ this.$store.state.per.PerNom }}</span></p>
      </v-col>
    </v-row>
   
    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-row dense>
          <p class="ml-8 mr-8" v-html="cuestionario.Descripcion" style="text-align: center"></p>
        </v-row>
      </v-col>
      
    </v-row>

    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-row dense>
          <input type="checkbox" id="opcion1" :value="1" v-model="horas">
          <label for="opcion1">&nbsp;<b>30 de mayo a partir de las 6.00 hasta media mañana.</b></label>
        </v-row>

        <v-row dense>
          <input type="checkbox" id="opcion2" :value="2" v-model="horas">
          <label for="opcion2">&nbsp;<b>30 de mayo en horario de mañana hasta las 16.30</b></label>
        </v-row>

        <v-row dense>
          <input type="checkbox" id="opcion3" :value="3" v-model="horas">
          <label for="opcion3">&nbsp;<b>31 de mayo a partir de las 6.00 hasta media mañana.</b></label>
        </v-row>

         
        
      </v-col>
    </v-row>

    <!-- </div> -->
    <br>
    <v-row dense >
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-btn
          color="primary"
          :loading="loading"
          @click="onClickSaveData"
        >
          Grabar inscripcion
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapState } from "vuex";
  // import router from '../router'
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'perAux', 'esEmpleado', 'urlRaiz'])
    },
    data: () => ({
      loading: false,
      cuestionario: {
      Respuestas: []
    },
      
    horas:[],

    respuestas:{
      horarios: { PreguntasCuestionarioId: 16, respuestaUsuario:[] },
    }
    

     
      // mostrarAlergenos: false
    }),

    methods: {
      // validate () {
      //   this.$refs.form.validate()
      //   if (this.valid)
      //   {
      //       this.enviar();
      //   }
      // },
      getDataFromApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Titulo: 'INSCRIPCION INVENTARIOS 2024 MAYO'
          };

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/get", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Cuestionario;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el cuestionario');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Respuestas: [ this.respuestas.horarios ],
            CuestionarioId: this.cuestionario.CuestionarioId,
            PerId: this.perId,
            DirId: 0
              //DirId: this.perAux != null && this.perAux.Dir != null && this.perAux.Dir.length > 0 ?
              //this.perAux.Dir[0].DirId : null
          };

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/respuesta/save", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Success;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el cuestionario');
            }
          });
        })
      },
      loadData(){
        this.getDataFromApi()
          .then(data => {
            this.cuestionario = data.item;
          });
      },
      onClickSaveData() {
        if (!this.esEmpleado) {
          alert('INSCRIPCION NO GUARDADA CORRECTAMENTE - Estas intentando guardar la participación desde el usuario de la tienda, inicia sesión desde tu intranet PERSONAL para grabar la inscripción');
          this.$router.push('/');
          return;
        }

        this.respuestas.horarios.respuestaUsuario = this.horas.toString();
        this.postDataToApi()
          .then(data => {
            const success = data.item;
            if (success)
            {
              alert('Muchas gracias por tu participación, se ha guardado correctamente');
              this.$router.push('/');
            }
          });
      },

    },
    mounted() {
      this.loadData();
    }
  }
</script>